// import moment from "moment";
import { AiOutlineHome } from "react-icons/ai";
import { GoChecklist } from "react-icons/go";
import { GoOrganization } from "react-icons/go";
// import { GoPeople } from "react-icons/go";
// import { BsSegmentedNav } from "react-icons/bs";
// import { LuGauge } from "react-icons/lu";
// import { RiRobot2Line } from "react-icons/ri";
// // import { VscRobot } from "react-icons/vsc";
// import { IoCreateOutline } from "react-icons/io5";


export const siderBarItems = () => {
  const sidebarData = [
    {
      path: "/home",
      icon: <AiOutlineHome className="h-6 w-6 group-hover:text-white" />,
      title: "Home",
      segment: true,
    },
    {
      path: "/Companies",
      icon: <GoOrganization className="h-6 w-6 group-hover:text-white" />,
      title: "All Companies",
      segment: true,
    },
    {
      path: "/contacts",
      icon: <AiOutlineHome className="h-6 w-6 group-hover:text-white" />,
      title: "Contacts",
      segment: true,
    },
    {
      path: "/calllogs",
      icon: <GoChecklist className="h-6 w-6 group-hover:text-white" />,
      title: "Call Logs",
      segment: true,
    },
    
    {
      path: "/reports",
      icon: <GoOrganization className="h-6 w-6 group-hover:text-white" />,
      title: "Reports",
      segment: false,
    },
    // {
    //   path: "/users",
    //   icon: <GoPeople className="h-6 w-6 group-hover:text-white" />,
    //   title: "Users",
    //   segment: false,
    // },
    // {
    //   path: "/createcustomsegment/null/null",
    //   icon: <BsSegmentedNav className="h-6 w-6 group-hover:text-white" />,
    //   icon: <IoCreateOutline className="h-6 w-6 group-hover:text-white" />,
    //   title: "Create Segment",
    //   segment: false,
    // },
    // {
    //   path: "/createdsegmentsperformance",
    //   icon: <LuGauge className="h-6 w-6 group-hover:text-white" />,
    //   title: "Created Segments Performance",
    //   segment: false,
    // },
    // {
    //   path: "/suggestedsegmentperformance",
    //   icon: <RiRobot2Line className="h-6 w-6 group-hover:text-white" />,
    //   title: "Suggested Segments Performance",
    //   segment: false,
    // },
  ];

  return sidebarData;
};
