import axios from 'axios'
import React, { useEffect, useState } from 'react'
import config_dev from '../../config'
import { encryptStorage } from '../../assets/js/encryptStorage'
import { FaBuilding } from "react-icons/fa";
import { TiContacts } from "react-icons/ti";
import { IoCallSharp } from "react-icons/io5";
import { FaPhoneSlash } from "react-icons/fa6";
import { MdOutlineCompareArrows } from "react-icons/md";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment/moment';
import { makeUrl } from '../../assets/js/buildURL';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

let cardStyle = "max-w-[17rem] min-w-[17rem] h-[7.3rem] border bg-white rounded-md flex justify-between items-center px-5"
let numStyle = "text-[#5D5FEF] text-4xl font-semibold mb-1"
let textStyle = "text-base text-gray-500"


const colors = [
  'rgba(255, 99, 132, 0.6)',
  'rgba(54, 162, 235, 0.6)',
  'rgba(255, 206, 86, 0.6)',
  'rgba(75, 192, 192, 0.6)',
  'rgba(153, 102, 255, 0.6)',
];
const options = { responsive: true, plugins: { legend: { position: 'top', display: "hidden" }, title: { display: false, text: 'Monthly Sales Data', }, }, scales: { y: { beginAtZero: true } }, };


const Home = () => {
  const [data, setData] = useState({})
  const [timeFilter, setTimeFilter] = useState({ current: "today", previous: "yesterday" })
  const [currentChartData, setCurrentChartData] = useState({ labels: [], datasets: [] });
  const [previousChartData, setPreviousChartData] = useState({ labels: [], datasets: [] });
  const [callLogs, setCallLogs] = useState([])
  const headers = { authorization: `Bearer ${encryptStorage.getItem('telemartToken')}` };


  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const fetchData = async () => {
    const URL = makeUrl("data", `getCallByStatus/${encryptStorage.getItem("userId")}`)
    let res = await axios.get(`${config_dev.BASE_URL}/user/dashboard/data/${encryptStorage.getItem("userId")}`)
    let calls = await axios.get(URL, { headers })
    setCallLogs(calls?.data?.successful)
    setData(res?.data?.data)
  }

  const changeTime = (time, status) => {
    setTimeFilter({ ...timeFilter, [status]: time })
  }

  const filterData = (filter) => {
    const now = moment();
    let startDate, endDate;

    switch (filter) {
      case 'today':
        startDate = now.clone().startOf('day');
        endDate = now.clone().endOf('day');
        break;

      case 'yesterday':
        startDate = now.clone().subtract(1, 'day').startOf('day');
        endDate = now.clone().subtract(1, 'day').endOf('day');
        break;

      case 'week':
        startDate = now.clone().startOf('week');
        endDate = now.clone().endOf('week');
        break;

      case 'past-week':
        startDate = now.clone().subtract(1, 'week').startOf('week');
        endDate = now.clone().subtract(1, 'week').endOf('week');
        break;

      case 'month':
        startDate = now.clone().startOf('month');
        endDate = now.clone().endOf('month');
        break;

      case 'past-month':
        startDate = now.clone().subtract(1, 'month').startOf('month');
        endDate = now.clone().subtract(1, 'month').endOf('month');
        break;

      case 'year':
        startDate = now.clone().startOf('year');
        endDate = now.clone().endOf('year');
        break;

      case 'past-year':
        startDate = now.clone().subtract(1, 'year').startOf('year');
        endDate = now.clone().subtract(1, 'year').endOf('year');
        break;

      case 'all':
        startDate = moment(0);
        endDate = now.clone();
        break;

      default:
        startDate = now.clone().startOf('day');
        endDate = now.clone().endOf('day');
    }
    return callLogs.filter((log) => moment(log.created_at).isBetween(startDate, endDate, undefined, '[)'));
  };

  const prepareChartData = (logs) => {
    const groupedData = logs.reduce((acc, log) => {
      const date = moment(log.created_at).format('YYYY-MM-DD');
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(groupedData).sort().map(date => moment(date).format('MMM-DD-YYYY'));
    const data = labels.map((label) => groupedData[moment(label, 'MMM-DD-YYYY').format('YYYY-MM-DD')]);
    const backgroundColors = labels.map(() => getRandomColor());
    return { labels, datasets: [{ label: 'Successful Calls', data, backgroundColor: backgroundColors, borderColor: backgroundColors.map(color => color.replace('0.6', '1')), borderWidth: 1, },], };
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const filteredLogsCurrent = filterData(timeFilter.current);
    const filteredLogsPrevious = filterData(timeFilter.previous);
    setCurrentChartData(prepareChartData(filteredLogsCurrent));
    setPreviousChartData(prepareChartData(filteredLogsPrevious));
  }, [timeFilter,callLogs]);


  return (

    <div className='my-10 mx-5 flex flex-col h-[100%]'>
      {/*CARD*/}
      <div className='flex justify-between items-center gap-x-3 overflow-x-auto'>

        <div className={cardStyle}>

          <div>
            <p className={numStyle}>{data?.companies}</p>
            <p className={textStyle}>Companies</p>
          </div>

          <div>
            <FaBuilding className='text-3xl text-gray-400' />
          </div>

        </div>

        <div className={cardStyle}>
          <div>
            <p className={numStyle}>{data?.contacts}</p>
            <p className={textStyle}>Contacts</p>
          </div>
          <div>
            <TiContacts className='text-3xl text-gray-400' />
          </div>
        </div>

        <div className={cardStyle}>
          <div>
            <p className={numStyle}>{data?.calls}</p>
            <p className={textStyle}>Calls Made</p>
          </div>
          <div>
            <IoCallSharp className='text-3xl text-gray-400' />
          </div>
        </div>

        <div className={cardStyle}>
          <div>
            <p className={numStyle}>{data?.unsuccessful}</p>
            <p className={textStyle}>Failed Calls</p>
          </div>
          <div>
            <FaPhoneSlash className='text-3xl text-gray-400' />
          </div>
        </div>
      </div>

      {/*CHARTS*/}
      <p className='mt-[2rem] text-xl'>Call Analysis</p>
      <div className='mt-3 flex-1 flex justify-between items-center relative'>

        {/*CURRENT*/}
        <div className='flex-1 p-5 h-[100%] bg-white '>

          {/*FILTERS*/}
          <div className='w-[100%] flex items-end justify-end mb-4'>
            <div className='flex items-center justify-center gap-x-2 bg-white border px-2 py-1 rounded-md'>
              <p onClick={() => changeTime("today", "current")} className={`border-r pr-2 border-gray-600 cursor-pointer ${timeFilter.current == "today" && "bg-slate-500 text-white py-1 pl-2 rounded-sm"}`}>Today</p>
              <p onClick={() => changeTime("week", "current")} className={`border-r pr-2 border-gray-600 cursor-pointer ${timeFilter.current == "week" && "bg-slate-500 text-white py-1 pl-2 rounded-sm"}`}>This Week</p>
              <p onClick={() => changeTime("month", "current")} className={`border-r pr-2 border-gray-600 cursor-pointer ${timeFilter.current == "month" && "bg-slate-500 text-white py-1 pl-2 rounded-sm"}`}>This Month</p>
              <p onClick={() => changeTime("year", "current")} className={`border-r pr-2 border-gray-600 cursor-pointer ${timeFilter.current == "year" && "bg-slate-500 text-white py-1 pl-2 rounded-sm"}`}>This Year</p>
              <p onClick={() => changeTime("all", "current")} className={`cursor-pointer mr-1 ${timeFilter.current == "all" && "bg-slate-500 text-white py-1 px-2 rounded-sm"}`}>All</p>
            </div>
          </div>
          {/* CHARTS  */}
          <Bar data={currentChartData} options={options} />
        </div>

        <div className='w-[0.8px] bg-slate-200 h-[100%]'></div>

        <div className='absolute top-[50%] left-[50%] translate-x-[-50%]'>
          <MdOutlineCompareArrows className='text-2xl'/>
        </div>

        {/*PREVIOUS*/}
        <div className='flex-1 p-5 h-[100%] bg-white '>
          {/* FILTERS  */}
          <div className='w-[100%] flex items-end justify-end mb-4'>
            <div className='flex items-center justify-center gap-x-2 bg-white border px-2 py-1 rounded-md'>
              <p onClick={() => changeTime("yesterday", "previous")} className={`border-r pr-2 border-gray-600 cursor-pointer ${timeFilter.previous == "yesterday" && "bg-slate-500 text-white py-1 pl-2 rounded-sm"}`}>Yesterday</p>
              <p onClick={() => changeTime("past-week", "previous")} className={`border-r pr-2 border-gray-600 cursor-pointer ${timeFilter.previous == "past-week" && "bg-slate-500 text-white py-1 pl-2 rounded-sm"}`}>Past Week</p>
              <p onClick={() => changeTime("past-month", "previous")} className={`border-r pr-2 border-gray-600 cursor-pointer ${timeFilter.previous == "past-month" && "bg-slate-500 text-white py-1 pl-2 rounded-sm"}`}>Past Month</p>
              <p onClick={() => changeTime("past-year", "previous")} className={`border-r pr-2 border-gray-600 cursor-pointer ${timeFilter.previous == "past-year" && "bg-slate-500 text-white py-1 pl-2 rounded-sm"}`}>Past Year</p>
              <p onClick={() => changeTime("all", "previous")} className={`cursor-pointer mr-1 ${timeFilter.previous == "all" && "bg-slate-500 text-white py-1 px-2 rounded-sm"}`}>All</p>
            </div>
          </div>
          {/* CHARTS  */}
          <Bar data={previousChartData} options={options} />
        </div>

      </div>

    </div>

  )
}

export default Home