// import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import MetaData from "../../components/MetaData";
import CustomButton from "../../components/Reusable/Button/CustomButton";
import { useState } from "react";
import axios from "axios";
import { makeUrl } from "../../assets/js/buildURL";
import toast from "react-hot-toast";
import { validateState } from '../../assets/js/helper'
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/slices/userSlice";
import Logo from '../../assets/images/telemart.png'
import { encryptStorage } from "../../assets/js/encryptStorage";

export default function Signup() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({ name: "", email: "", password: "", repassword: "" })

    const handelFormChange = (key, e) => {
        setFormData({ ...formData, [key]: e.target.value })
    }

    const handleClick = async () => {
        if (formData.password != formData.repassword) { return toast.error("Passwords Do not match") }
        const validateStateRes = validateState(formData, ["name", "email", "password"])
        if (validateStateRes) { return toast.error(validateStateRes) }
        const toastRef = toast.loading("loading")
        try {
            const Url = await makeUrl("user", "signup")
            const res = await axios.post(Url, formData)
            const data = res?.data
            encryptStorage.setItem("telemartToken", data?.token)
            dispatch(loginUser(data))
            toast.success("Signup successful")
            navigate("/companies")
        }
        catch (error) {
            console.error(error.message)
            toast.error(error?.response?.data?.message || error.message)
        }
        toast.dismiss(toastRef)
    }

    return <div>
        <MetaData title={'Login'} />
        <div className="flex  flex-col absolute justify-center items-center min-h-full lg:min-h-screen w-full " >
            <div className=" bg-white py-4 px-[3rem] rounded-lg  md:shadow-2xl lg:w-1/3 " >
                <div className="w-[100%] flex justify-center items-center">
                    <img src={Logo} alt="" className="h-[7rem]" />
                </div>
                <h2 className="text-3xl font-bold text-slate-700 text-center"><span className="text-blue-500">Tele</span>market</h2>
                <h2 className="text-lg font-semibold  mt-4 mb-4 text-slate-500 text-center">Create Your <span className="text-blue-500">Account !</span></h2>
                <input className="w-[100%] border border-[1px solid ligtgray] h-[2.3rem] outline-none rounded-md px-3" placeholder="Name" value={formData.name} onChange={e => handelFormChange("name", e)} />
                <input className="w-[100%] border border-[1px solid ligtgray] h-[2.3rem] outline-none rounded-md px-3 mt-3" placeholder="Email" type="email" value={formData.email} onChange={e => handelFormChange("email", e)} />
                <input placeholder="Password" type="password" value={formData.password} onChange={e => handelFormChange("password", e)} className="w-[100%] border border-[1px solid ligtgray] h-[2.3rem] outline-none rounded-md px-3 mt-3" />
                <input placeholder="Re-Password" type="password" value={formData.repassword} onChange={e => handelFormChange("repassword", e)} className="w-[100%] border border-[1px solid ligtgray] h-[2.3rem] outline-none rounded-md px-3 mt-3" />
                <div className="flex items-center justify-end mt-2">
                    <p className="text-sm">Already have an account? <span className="font-semibold cursor-pointer" onClick={()=>navigate("/")}>Login</span></p>
                </div>
                <div className="flex items-center justify-end  mt-4">
                    <CustomButton label={'signup'} classname={"bg-green-500 hover:bg-green-600"} onClick={handleClick} />
                </div>
            </div>
        </div>
    </div>
}

